import "./VideoPOITracker.css";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useVideoPlayer} from "./VideoPlayerProvider";
import Config from "../../utility/Config";
import ReactDOM from "react-dom";
import {fetcher} from "../../utility/Backend";
import classNames from "classnames";
import {FaStepBackward, FaStepForward} from "react-icons/fa";


const ENTITY_TYPES = Config.association === "SHL" ?
    ["puck", "player", "goaltender", "referee", "centerIce", "faceoff", "goal"] :
    ["ball", "player", "goalkeeper", "referee"]
const RENDERED_ENTITY_CLS = {
    "ball": "poi-entity-ball",
    "puck": "poi-entity-ball",
    "player": "poi-entity-player",
    "referee": "poi-entity-referee",
    "goalkeeper": "poi-entity-keeper",
    "goaltender": "poi-entity-keeper",
}

function deconstructPlaybackUrl (url) {
    if (!url) return [null, null, null]
    const urlParts = url.split("/")
    const playlistQuery = urlParts[urlParts.length - 2]
    // FIXME: Should we support composite playlists?
    if (!playlistQuery || playlistQuery.includes("_")) return [null, null, null]
    const queryParts = playlistQuery.split(":")
    return [
        parseInt(queryParts[0], 10),
        parseInt(queryParts[1], 10),
        parseInt(queryParts[2], 10)
    ]
}

function halfWidthOfRectangle (childAspectRatio, parentAspectRatio=16/9) {
    if (typeof childAspectRatio === "string")
        childAspectRatio = childAspectRatio.split("/").reduce((num, den) => num / den)
    return childAspectRatio / (2*parentAspectRatio)
}

function secToFrame (seconds, fps) {
    return Math.floor(seconds * fps)
}

function getFieldVisionUrl (path, league, assetId, firstFrame, lastFrame, extra="") {
    return `/fieldvision/v1/${path}/${league}/${assetId}?firstFrame=${firstFrame}&lastFrame=${lastFrame}${extra}`
}

function linear2D (first, firstWeight, second, secondWeight) {
    return {
        x: Math.min(1, first.x * firstWeight + second.x * secondWeight),
        y: Math.min(1, first.y * firstWeight + second.y * secondWeight)
    }
}

function getInterpolatedFromMap (
    map,
    frameNumber,
    fallback=undefined,
    interpolationFn=undefined,
    distance=6
) {

    if (map.has(frameNumber)) return map.get(frameNumber)

    let left = undefined
    let right = undefined
    for (let offset = 1; offset <= distance; ++offset) {
        if (map.has(frameNumber - offset)) {
            if (!interpolationFn) return map.get(frameNumber - offset)
            left = {offset: offset, value: map.get(frameNumber - offset)}
        }
        if (map.has(frameNumber + offset)) {
            if (!interpolationFn) return map.get(frameNumber + offset)
            right = {offset: offset, value: map.get(frameNumber + offset)}
        }
        if (left && right) break
    }
    if (left === undefined && right === undefined) return fallback
    if (left === undefined) return right.value
    if (right === undefined) return left.value
    const totalOffset = left.offset + right.offset
    return interpolationFn(left.value, 1 - left.offset / totalOffset, right.value, 1 - right.offset / totalOffset)
}

function VisualizeEntity ({
    x1,
    y1,
    x2,
    y2,
    type,
    probability,
    trackId,
}) {
    const cls = RENDERED_ENTITY_CLS[type] || "poi-entity-other"
    return (
        <div className={`poi-entity ${cls}`}
             data-info={`${type}\n#${trackId}\n${probability}`}
             style={{
                 left: `${x1 * 100}%`,
                 top: `${y1 * 100}%`,
                 width: `${(x2 - x1) * 100}%`,
                 height: `${(y2 - y1) * 100}%`,
             }}
        />
    )
}

function VisualizePOI ({
    x, // Between 0 and 1
    y, // Between 0 and 1
    rectangle=true,
    cross=true,
    dimming=true,
    aspectRatio="9/16"
}) {
    let rectangleEl = null
    if (rectangle) {
        const halfWidth = halfWidthOfRectangle(aspectRatio) * 100
        const left =  Math.max(halfWidth, Math.min(100-halfWidth, x*100))
        rectangleEl = (
            <>
                <div className="poi-rectangle" style={{aspectRatio: aspectRatio,left: `${left}%`}} />
                {dimming && (
                    <>
                        <div className="dimming-left" style={{width: `${left-halfWidth}%`}} />
                        <div className="dimming-right" style={{width: `${100 - left - halfWidth}%`}} />
                    </>
                )}
            </>
        )
    }

    return (
        <>
            {rectangleEl}
            {cross && (
                <div className="poi-cross" style={{
                    left: `${x*100}%`,
                    top: `${y*100}%`
                }} />
            )}
        </>
    )
}

function VideoOverlay ({
    assetId,
    assetTimeOffset, // How far into the videoasset our video starts
    aspectRatio,
    showEntities,
    fps=Config.expectedFrameRate,
    chunkDuration=60000
}) {
    const {getCurrentTime} = useVideoPlayer()

    const [frameNumber, setFrameNumber] = useState(0)
    // Indicate if we've already loaded, or started loading, a time chunk of FV data
    const chunkStatus = useMemo(() => new Map(), [assetId, fps])

    // Maps to store FieldVision data
    const poiMap = useMemo(() => new Map(), [assetId, fps])
    const sceneMap = useMemo(() => new Map(), [assetId, fps])
    const whistleMap = useMemo(() => new Map(), [assetId, fps])
    const audioMap = useMemo(() => new Map(), [assetId, fps])
    const transitionMap = useMemo(() => new Map(), [assetId, fps])
    const entityMap = useMemo(() => new Map(), [assetId, fps])

    const calculateChunkRange = (timestamp) => {
        const start = Math.floor(timestamp / chunkDuration) * chunkDuration
        return [start, start + chunkDuration]
    }

    const fetchChunkData = async (startTimestamp, endTimestamp) => {
        console.log("Fetching chunk", startTimestamp, endTimestamp)
        const startFrame = secToFrame(startTimestamp / 1000, fps)
        const endFrame = secToFrame(endTimestamp / 1000, fps)
        try {
            const [
                poiResp,
                scenesResp,
                transitionsResp,
                whistleResp,
                audioResp,
                entityResp
            ] = await Promise.all([
                fetcher(getFieldVisionUrl("poi", Config.database, assetId, startFrame, endFrame)),
                fetcher(getFieldVisionUrl("scenes", Config.database, assetId, startFrame, endFrame)),
                fetcher(getFieldVisionUrl("transitions", Config.database, assetId, startFrame, endFrame)),
                fetcher(getFieldVisionUrl("audio/whistle", Config.database, assetId, startFrame, endFrame)),
                fetcher(getFieldVisionUrl("audio/rms", Config.database, assetId, startFrame, endFrame, "&track=-1")),
                fetcher(getFieldVisionUrl("videoentities", Config.database, assetId, startFrame, endFrame)),
            ])

            poiResp.forEach(({frameNumber, id, x, y}) => {
                poiMap.set(frameNumber, {id, x, y})
            })

            scenesResp.forEach(({ firstFrame, lastFrame, metadata }) => {
                const { cameraZoom, cameraZoomWarning } = metadata
                const msg = cameraZoom + (cameraZoomWarning ? ` (${cameraZoomWarning})` : "")
                for (let frame = firstFrame; frame <= lastFrame; frame++) {
                    sceneMap.set(frame, msg)
                }
            })

            transitionsResp.forEach(({ firstFrame, lastFrame, transitionType }) => {
                for (let frame = firstFrame; frame <= lastFrame; frame++) {
                    transitionMap.set(frame, transitionType)
                }
            })

            whistleResp.forEach(({ firstFrame, lastFrame}) => {
                for (let frame = firstFrame; frame <= lastFrame; frame++) {
                    whistleMap.set(frame, true)
                }
            })

            audioResp.forEach(({ firstFrame, lastFrame, rms }) => {
                for (let frame = firstFrame; frame <= lastFrame; frame++) {
                    // In case there are rms for multiple tracks, just use the highest
                    if (audioMap.get(frame)) rms = Math.max(audioMap.get(frame), rms)
                    audioMap.set(frame, rms)
                }
            })

            entityResp.forEach(({ id, videoEntityType, trackId, probability, frameNumber, x1, x2, y1, y2 }) => {
                const arr = entityMap.get(frameNumber) || []
                arr.push({id, x1, x2, y1, y2, type: videoEntityType, trackId, probability})
                entityMap.set(frameNumber, arr)
            })

            console.log("Stored chunk", startTimestamp, endTimestamp)
        } catch (error) {
            console.error("Error fetching chunk data:", error)
            // Optionally retry or log failure
        }
    }

    const onTimeUpdate = useCallback(() => {
        const currentTime = getCurrentTime() * 1000 + assetTimeOffset
        const [currentChunkStart, currentChunkEnd] = calculateChunkRange(currentTime)
        const [nextChunkStart, nextChunkEnd] = [currentChunkEnd, currentChunkEnd + chunkDuration]

        // Update the state with current frame's data
        setFrameNumber(secToFrame(currentTime / 1000, fps))
        // Ensure current and next chunks are fetched
        const checkChunk = (start, end) => {
            const chunkKey = `${start}-${end}`
            if (chunkStatus.has(chunkKey)) return
            chunkStatus.set(chunkKey, true) // Mark as started downloading
            fetchChunkData(start, end)
        }
        checkChunk(currentChunkStart, currentChunkEnd)
        checkChunk(nextChunkStart, nextChunkEnd)

    }, [chunkStatus, assetTimeOffset, fps])

    useEffect(() => {
        onTimeUpdate()
        const interval = setInterval(onTimeUpdate, 100)
        return () => clearInterval(interval)
    }, [onTimeUpdate])

    const poi = getInterpolatedFromMap(poiMap, frameNumber, { x: 0.5, y: 0.5 }, linear2D)
    const cameraZoom = getInterpolatedFromMap(sceneMap, frameNumber)
    const transition = getInterpolatedFromMap(transitionMap, frameNumber)
    const whistle = getInterpolatedFromMap(whistleMap, frameNumber)
    const audioRms = getInterpolatedFromMap(audioMap, frameNumber, 0)
    const entities = getInterpolatedFromMap(entityMap, frameNumber, [], undefined)

    const entitiesToShow = entities.filter(({type}) => (
        showEntities.includes(ENTITY_TYPES.includes(type) ? type : "other")
    ))

    return (
        <>
            {entitiesToShow.map((e, idx) => (
                <VisualizeEntity key={`${idx}_${e.type}`}
                                 type={e.type}
                                 probability={e.probability}
                                 trackId={e.trackId}
                                 x1={e.x1}
                                 y1={e.y1}
                                 x2={e.x2}
                                 y2={e.y2}
                />
            ))}
            {aspectRatio && (
                <VisualizePOI
                    x={poi.x}
                    y={poi.y}
                    rectangle={true}
                    cross={true}
                    dimming={true}
                    entities={entities}
                    aspectRatio={aspectRatio}
                />
            )}
            <div className="poi-tracker-frame-info">
                <div>{assetId} / {frameNumber}</div>
                <div className={classNames({"attention-text": audioRms > 0.085})}>
                    Audio: {audioRms.toFixed(4)}
                </div>
                <div>{cameraZoom || "Camera unset"}</div>
                {transition && (
                    <div className="attention-text">{transition}</div>
                )}
                {whistle && (
                    <div className="attention-text">Whistle detected</div>
                )}
            </div>
        </>
    )
}

function EntityCheckboxes ({ options, active, onChange }) {
    const handleCheckboxChange = (entity) => {
        const updatedEntities = active.includes(entity)
            ? active.filter(item => item !== entity)
            : [...active, entity]
        onChange(updatedEntities)
    }

    const handleAllChange = () => {
        if (active.length === options.length + 1) {
            onChange([]) // Deselect all
        } else {
            onChange([ ...options, "other" ]) // Select all
        }
    }

    return (
        <div className="checkbox-container">
            <label className="checkbox-item">
                <input
                    type="checkbox"
                    checked={active.length === options.length + 1}
                    onChange={handleAllChange}
                />
                <span>All</span>
            </label>
            {options.map((option) => (
                <label key={option} className="checkbox-item">
                    <input
                        type="checkbox"
                        checked={active.includes(option)}
                        onChange={() => handleCheckboxChange(option)}
                    />
                    <span>{option}</span>
                </label>
            ))}
            <label className="checkbox-item">
                <input
                    type="checkbox"
                    checked={active.includes("other")}
                    onChange={() => handleCheckboxChange("other")}
                />
                <span>Other</span>
            </label>
        </div>
    )
}

function JumpFramesButton ({ value, onClick, display=null }) {
    display = display || `${Math.abs(value)}f`
    const seconds = value / Config.expectedFrameRate
    return (
        <button className="jump-btn" onClick={() => onClick(seconds)} style={{ position: "relative" }}>
            {value < 0 ? (
                <><FaStepBackward /> <span>{display}</span></>
            ) : (
                <><span>{display}</span> <FaStepForward /></>
            )}
        </button>
    )
}


export default function VideoPOITracker () {
    const {playbackUrl, playerRef} = useVideoPlayer()
    const [container, setContainer] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(null)
    const [enabledEntities, setEnabledEntities] = useState(() => [])
    let [assetId, assetTimeOffset] = deconstructPlaybackUrl(playbackUrl)

    useEffect(() => {
        if (!playerRef.current) return
        const controlBar = playerRef.current.el().querySelector(".vjs-control-bar")
        if (!controlBar) return

        const newContainer = document.createElement("div")
        newContainer.className = "poi-cont"
        controlBar.insertAdjacentElement("beforebegin", newContainer)
        setContainer(newContainer)
        return () => {
            if (newContainer && newContainer.parentNode) {
                newContainer.parentNode.removeChild(newContainer)
            }
        }
    }, [playerRef.current])

    if (!assetId) return null // Disabled for compilations, at least for now

    function onSkip (skip) {
        const videoJs = playerRef.current
        if (!videoJs) return
        // if (!videoLoaded) videoJs.play()
        const currentTime = videoJs.currentTime()
        videoJs.currentTime(Math.max(0, currentTime + skip))
    }

    return (
        <div className="poi-tracker-cont">
            <div>
                <div>
                    Skip frames:
                </div>
                <JumpFramesButton value={-100} onClick={onSkip} />
                <JumpFramesButton value={-25} onClick={onSkip} />
                <JumpFramesButton value={-5} onClick={onSkip} />
                <JumpFramesButton value={-1} onClick={onSkip} />
                <JumpFramesButton value={1} onClick={onSkip} />
                <JumpFramesButton value={5} onClick={onSkip} />
                <JumpFramesButton value={25} onClick={onSkip} />
                <JumpFramesButton value={100} onClick={onSkip} />
            </div>
            <div className="poi-tracker-ar-cont">
                <div>
                    Cropping preview:
                </div>
                <button type="button"
                        disabled={aspectRatio === null}
                        onClick={() => setAspectRatio(null)}>
                    Off
                </button>
                <button type="button"
                        disabled={aspectRatio === "1/1"}
                        onClick={() => setAspectRatio("1/1")}>
                    1:1
                </button>
                <button type="button"
                        disabled={aspectRatio === "9/16"}
                        onClick={() => setAspectRatio("9/16")}>
                    9:16
                </button>
            </div>
            <div>
                <div>
                    Display entities:
                </div>
                <EntityCheckboxes
                    options={ENTITY_TYPES}
                    active={enabledEntities}
                    onChange={setEnabledEntities}
                />
            </div>
            {container && (aspectRatio || enabledEntities.length > 0) && (
                ReactDOM.createPortal(
                    <>
                        <VideoOverlay
                            assetId={assetId}
                            assetTimeOffset={assetTimeOffset}
                            aspectRatio={aspectRatio}
                            showEntities={enabledEntities}
                        />
                    </>,
                    container
                )
            )}
        </div>
    )
}
